import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import PaintingGrid from '../components/paintings/paintingGrid'
import Timeline from '../components/timeline'
import {Helmet} from "react-helmet";

export default function Year({ data, pageContext: {title, paintings, regex, topimage, availableYear } }) {
  const year = title;
  const images = data.images.nodes
  return (
      <>
        <Helmet htmlAttributes={{ lang : 'en-uk' }}>
          <meta charSet="utf-8" />
          <title>{`Alan Macdonald | ${year}`} </title>
          <meta charSet="utf-8" />
          <meta name="description" content={`${year} by Alan Macdonald`}/>
          <meta name="keywords" content={`Alan Macdonald, ${year}, painting, Scottish, artist, art, Alan, Macdonald`}/>
          <meta name="author" content="Alan Macdonald"/>
          <meta property="og:title" content={year} />
          <meta property="og:description" content={`${year} by Alan Macdonald`} />
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:site" content="@twitter"/>
          <meta property="twitter:title" content="Alan Macdonald"/>
          <meta property="twitter:description" content={title}/>


        </Helmet>
    <Layout current={year}>
    <Timeline available={!!availableYear} current={year} />
    <main>
      <div>
        <PaintingGrid available={availableYear ? true : false} images={images} title={year} paintings={paintings} />
      </div>
      </main>
    </Layout>
        </>
  );
}
export const query = graphql`
  query($regex: String!) {

          images: allS3Object (filter : {Key: {regex: $regex}}) {
            nodes {
              Key
              localFile {
                  relativePath
                  childImageSharp {
                      gatsbyImageData(
                        width: 800
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        formats: [JPG]
                      )
                    }
                }
              }
            }
          }
  
`;

// yearsJson( title: { eq: $title} ) {
//   title
// }
// allPaintingsJson (sort: {order: ASC, fields: title}, filter: {year : {eq: $title}}) {
//       nodes {
//         id,
//         title,
//         filepath
//       }
//   }
